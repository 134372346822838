import { useContext, useState } from "react";
import csvDownload from 'json-to-csv-export';

import { CenteredDiv } from "../../../Global/StyledComponents/AppStyledComponents";
import { ExportIcon } from "../../../Global/Icons/Icons";
import { Form, Header, Input, StyledButton, WarningText } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { formatExportDate, formatPrice, ordinalSuffixOf, round } from "../../../Controllers/utils";
import Loading from "../../../Global/Components/LoadingSpinner";
import { StateContext } from '../../../Controllers/context/state';

const ExportPopUp = () => {
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState(false);
    const {
        cardData,
        loadingExport,
        setShowExportPopUp,
        visibleCards,
    } = useContext(StateContext);

    const handleChange = (e) => {
        setError(false);
        setFileName(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fileName.length) {
            setError(true);

            return;
        }
        
        const exportData = Object.keys(visibleCards).map(key => ({
            symbol: cardData[key]?.symbol,
            name: cardData[key]?.name,
            sector: cardData[key]?.gsector || '',
            subIndustry: cardData[key]?.gsubind || '',
            price: formatPrice(cardData[key]?.close),
            return: `${round(cardData[key]?.date_return_oc * 100, 1)}%`,
            percentileReturn: `${ordinalSuffixOf(round(cardData[key]?.date_return_oc_percentile * 100, 0))}`,
            volAdjReturn: `${round(cardData[key]?.date_return_oc_voladj)}σ`,
            abbreviatedInsight: cardData[key]?.dtm_summary?.dtm_headline || '',
            insights: cardData[key]?.dtm_summary?.dtm_summary || '',
            published: cardData[key]?.dtm_summary?.time_utc ? formatExportDate(new Date (cardData[key]?.dtm_summary?.time_utc)) : '',
        }));

        const dataToConvert = {
            data: exportData,
            filename: fileName,
            delimiter: ',',
            headers: [
                'Symbol',
                'Name',
                'Sector',
                'Sub Industry',
                'Price',
                'Return',
                'Percentile Return',
                'Volatility Adjusted Return',
                'Abbreviated Insight',
                'Insights',
                'Published',
            ],
          };

          csvDownload(dataToConvert);
          setShowExportPopUp(false);
    };

     return (
        <Form onSubmit={handleSubmit}>
            <Header>
                Export Data For Listed Assets
            </Header>
            {loadingExport ?
                <Loading /> :
                <CenteredDiv>
                    {Object.keys(visibleCards).join(', ')}
                </CenteredDiv>
            }
            <br />
            <Input onChange={handleChange} placeholder='Enter File Name' style={{ width: '95%', margin: 0 }} type='text' value={fileName}/>
            <br />
            {error &&
                <WarningText>
                    Missing File Name.
                </WarningText>
            }
            <StyledButton disabled={loadingExport} type='submit'>
                Export CSV 
                <ExportIcon />
            </StyledButton>

        </Form>
     )
};

export default ExportPopUp;
