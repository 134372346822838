import { formatMarketCap, formatPrice, round } from './index';

const setSortValue = (data, key) => {

    const SortDisplay = {
        beta: {
            value: `${round(data.beta, 2)}`,
        },
        close: {
            value: `${formatPrice(data.close)}`,
        },
        correlation: {
            value: `${round(data.correlation, 2)}`,
        },
        date_return_oc_voladj:{
            value: `${round(data.date_return_oc_voladj)}σ`,
        },
        date_return_oc: {
            value: `${round(data.date_return_oc * 100)}%`,
        },
        'date_return_oc*mcap_mm': {
            value: `${formatMarketCap(data.date_return_oc * data.mcap_mm)}`,
        },
    };

    return (
        <>
            {SortDisplay[key].value}
        </>
    );
};

export default setSortValue;
