import { useRef } from 'react';
import styled from 'styled-components';
import { SubmitButton, Container, Header } from '../../Global/StyledComponents/UserFormStyledComponents';
import LoginForm from './components/LoginForm';
import SampleView from './components/SampleView';
import { WhiteTextGlassLogo } from '../../Global/Logos/Logos';
import { AppColors } from '../../Global/StyledComponents/Graphics';
import { isMobile, isTablet } from "react-device-detect";
import useOnScreen from '../../Controllers/hooks/onScreenHook';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Global/Components/Footer';

export const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(!isMobile || isTablet) ? '40%' : '100%'};
    height: ${(!isMobile || isTablet) ? '100vh' : 'auto'};
    background-color: ${AppColors.MRNAVY15};
    padding: ${(!isMobile || isTablet) ? 0 : '20px 0'};
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${(!isMobile || isTablet) ? 0 : '200px'};
`;

function LoginMonitor({
    setCustomerProfile,
    setIsAuthenticated,
    setRefreshed,
    setSession,
    setSubscription,
    setUser
}) {
    const containerRef = useRef();
    const loginRef = useRef();
    const isVisible = useOnScreen(loginRef, 1);
    const navigate = useNavigate();

    const handleScroll = () => {
        containerRef?.current?.scrollIntoView({
            block: 'end',
            behavior: "smooth",
        });
    };

    const handleSignUp = (e) => {
        navigate('/signup');
    };
    return (
        <Container
            isMobile={isMobile && !isTablet}
            ref={containerRef}
            style={{ justifyContent: 'space-between', flexDirection: (!isMobile || isTablet) ? 'row' : 'column' }}
        >
            {!isVisible &&
                <SubmitButton
                    onClick={handleScroll}
                    style={{ position: 'fixed', top: 10, right: 10, width: 'fit-content', zIndex: 999}}
                >
                    Sign In
                </SubmitButton>
            }
            <SampleView />
            <LoginWrapper>
                <LoginContainer
                    ref={loginRef}
                >
                    {(!isMobile || isTablet) &&
                    <>
                        <WhiteTextGlassLogo title="Market Reader logo" />
                        <Header>Get Access To Real-Time Market Insights</Header>
                        <SubmitButton style={{ width: 'fit-content' }}
                            onClick={handleSignUp}
                        >
                            Request Trial Access
                        </SubmitButton>
                        <br />
                        <Header>Or</Header>
                        <br />
                    </>
                    }
                    <LoginForm
                        setCustomerProfile={setCustomerProfile}
                        setIsAuthenticated={setIsAuthenticated}
                        setRefreshed={setRefreshed}
                        setSession={setSession}
                        setSubscription={setSubscription}
                        setUser={setUser}
                    />
                </LoginContainer>
            </LoginWrapper>
            {(isMobile && !isTablet) &&
                <LoginWrapper
                    style={{ height: 'auto', position: 'fixed', bottom: 0, zIndex: 999}}
                >
                    <WhiteTextGlassLogo title="Market Reader logo" />
                    <Header>Get Access To Real-Time Market Insights</Header>
                    <SubmitButton
                       onClick={handleSignUp}
                    >
                        Request Trial Access
                    </SubmitButton>
                </LoginWrapper>
            }
            <Footer />
        </Container>
    );
}
export default LoginMonitor;
