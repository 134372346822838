import { getPercentileColor } from '../../../../../../Controllers/utils';
import supabase from '../../../../../../Controllers/utils/supabaseClient';
import { StockChartColors } from '../../../../../StyledComponents/Graphics';

export const fetchMarkers = async ({
	aggregate,
	endPoint,
	epoch,
	retry = true,
    return_percentile_floor,
	symbol,
}) => {
	const headers = aggregate ? {
		_aggr: aggregate,
		_symbol: symbol,
		_epoch: epoch,
        _return_percentile_floor: return_percentile_floor
	} : {
		_symbol: symbol,
	};

	const { data, error } = await supabase.rpc(endPoint, headers);

	if (error) {
		if (retry) {
			return fetchMarkers({
				aggregate,
				endPoint,
				epoch,
				retry: false,
				symbol,
			});
		}

		if (
			confirm(`There was an error loading the chart data.\n\nIf the error persists, please take a screenshot and send to support@marketreader.com\n\nError Message: ${error?.message}`)
		) {
			window.location.reload();
		} else {
			window.location.reload();
		}

		return;
	}

	return data;
};

export const fetchBars = async ({
	count,
	endDate,
	endPoint,
	retry = true,
	symbol,
}) => {
	const { data, error } = await supabase.rpc(endPoint, {
		amount: count,
		ticker: symbol,
		epoch_date: endDate,
	});

	if (error) {
		if (retry) {
			return fetchBars({ count, endDate, endPoint, retry: false, symbol });
		}

		if (
			confirm(`There was an error loading the chart data.\n\nIf the error persists, please take a screenshot and send to support@marketreader.com\n\nError Message: ${error?.message}`)
		) {
			window.location.reload();
		} else {
			window.location.reload();
		}

		return;
	}

	return data;
};


export const formatLineData = (data) => {
	return data.map((point) => ({
		x: point.time_utc,
		y: point.close,
        custom:point,
	}));
};


export const formatScatter = (scatterData, lineData) => {
	const result = [];

	scatterData.forEach((scatterItem) => {
		const ts = Number(scatterItem.ts);

		const existingData = lineData.find((lineItem) => {
			const time_utc = Number(lineItem.time_utc);

			return time_utc === ts;
		});

		if (existingData) {
			const closePrice = existingData.close;
			const percentile =
				scatterItem.performance_stats.date_return_oc_percentile;
			const color = getPercentileColor(percentile);
			result.push({
				x: ts,
				y: closePrice,
				color: StockChartColors[color],
				custom: scatterItem.performance_stats,
			});
		}
	});

	return result;
};

export const formatVolume = (data) => {
	return data.map((point) => {
		let color;
		if (point.open > point.close) {
			color = StockChartColors.ZETA;
		} else {
			color = StockChartColors.ALPH;
		}

		return {
			x: point.time_utc,
			y: point.volume,
			color: color,
		};
	});
};

export const formatExtendedHours = (data) => {
    const interval = 10 * 60 * 1000;
    let hasRthBeenTrue = false; 
    
    return data.reduce((acc, point, index, array) => {
        if (point.market_rth === true) {
            hasRthBeenTrue = true; 
            
            if (index > 0) {
                const prevPoint = array[index - 1];
                if (point.time_utc - prevPoint.time_utc > interval) {
                    acc.push({ x: point.time_utc, y: null });
                }
            }
            acc.push({
                x: point.time_utc,
                y: point.close,
                custom: point,
            });
        } else if (hasRthBeenTrue) {
            acc.push({
                x: point.time_utc,
                y: point.close,
                custom: point,
            });
            hasRthBeenTrue = false;
        }else{
            acc.push({ x: point.time_utc, y: null });
        }
        
        return acc;
    }, []);
};

export const formatTooltipVolume = (volume) => {
    if (volume < 1000) return volume.toString();
    if (volume < 1000000) return (volume / 1000).toFixed(1) + 'k';

    return (volume / 1000000).toFixed(1) + 'm';
}

export const generateRthPlotBands = (data, color = '#0c2233') => {
    const plotBands = [];
    let segmentStart = null;

    data.forEach((point, index) => {
        const nextPoint = data[index + 1];

        if (point.market_rth) {
            if (segmentStart === null) {
                segmentStart = point.time_utc;
            }
            if (!nextPoint || !nextPoint.market_rth) {
                plotBands.push({
                    color: color,
                    from: segmentStart,
                    to: point.time_utc
                });
                segmentStart = null;
                
                if (nextPoint && !nextPoint.market_rth) {
                    plotBands.push({
                        color: color,
                        from: point.time_utc,
                        to: nextPoint.time_utc
                    });
                }
            }
        }
    });

    return plotBands;
};