import { useContext, useEffect, useState } from "react";
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

import { CopyIcon } from "../../../Global/Icons/Icons";
import { copyTextToClipboard, formatDateYear, insertInOrder } from "../../../Controllers/utils";
import { Header, StyledButton } from "../../../Global/StyledComponents/UserFormStyledComponents";
import { IconBox } from "../../../Global/StyledComponents/StockCardStyledComponents";
import { MarketReaderWatermark } from "../../../Global/Logos/Logos";
import { StateContext } from "../../../Controllers/context/state";
import supabase from "../../../Controllers/utils/supabaseClient";

const SummaryContainer = styled.div`
    margin-top: 15px;
    position: relative;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

const SummaryWrapper = styled.div`
    padding: 10px;
    column-count: ${(!isMobile || isTablet) ? 2 : 1};
    column-gap: 20px;
    height: 100%;
`;

const StyledParagraph = styled.p`
    font-size: 20px;
    margin-bottom: 10px;
`;

const MonthlySummary = () => {
    const {
        expandedViewTicker,
        monthlySummaryTimestamp,
        logError,
        setShowMonthlySummary,
    } = useContext(StateContext);
    const [isCopied, setIsCopied] = useState(false);
    const [monthlySummary, setMonthlySummary] = useState(null);
    const [timestamps, setTimestamps] = useState(null);
    const [selectedTimestamp, setSelectedTimestamp] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const handleCopyClick = () => {
        copyTextToClipboard(monthlySummary?.summaryLines?.join('\n\n')).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    };

    const handleBack = () => {
        setSelectedTimestamp(prevState =>
            prevState !== 0 ? prevState - 1 : 0
        );
    };

    const handleForward = () => {
        setSelectedTimestamp(prevState =>
            prevState !== timestamps.length - 1 ? prevState + 1 : timestamps.length - 1
        );
    };

    useEffect(() => {
        const fetchTimestamps = async ({ endPoint, retry = true, symbol }) => {
            const { data, error } = await supabase.rpc(endPoint, { _symbol: symbol });

                    
            if (error) {
                const error = new Error(error?.message);
                logError(error.message, undefined, error);

                if (retry) {
                    fetchData({ retry: false });

                    return;
                }
    
                return;
            }

            let mappedData = data.map(timestamp => timestamp.ts);
            if (monthlySummaryTimestamp) {
                mappedData = insertInOrder(mappedData, monthlySummaryTimestamp);
            } else {
                mappedData.push(null);
            }

            setTimestamps(mappedData);
            setSelectedTimestamp(
                monthlySummaryTimestamp ?
                mappedData?.findIndex(timestamp => timestamp === monthlySummaryTimestamp) :
                mappedData?.length - 1
            );
        };

        fetchTimestamps({
            endPoint: 'get_monthly_marker_timestamps',
            retry: true,
            symbol: expandedViewTicker,
        });
    }, []);
    
    useEffect(() => {
        const fetchData = async ({ retry = true }) => {
            let res;
            if (monthlySummaryTimestamp || timestamps?.[selectedTimestamp]) {
                res = await supabase.rpc('get_long_term_summary', {
                    epoch_date_utc: timestamps[selectedTimestamp] / 1000,
                    freq: '5W', 
                    ticker: expandedViewTicker,
                })
            } else {
                res = await supabase.rpc('get_long_term_summary', {
                    freq: '5W', 
                    ticker: expandedViewTicker,
                })
            }

            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry) {
                    fetchData({ retry: false });

                    return;
                }

                setMonthlySummary(null);
                setShowMonthlySummary(false);
    
                return;
            }

            if (res?.data?.[0]?.data_row?.summary?.length < 1) {
                setMonthlySummary(null);
                setShowMonthlySummary(false);
            }
            
            var startDate = new Date(res?.data?.[0]?.time_utc_end);
            const fourWeeksInMilliseconds = 4 * 7 * 24 * 60 * 60 * 1000;
            startDate.setTime(startDate.getTime() - fourWeeksInMilliseconds);
            setMonthlySummary({
                published: res?.data?.[0]?.time_utc_end,
                summaryLines: res?.data?.[0]?.data_row?.summary?.split('\n\n'),
                fromDate: res?.data?.[0]?.window_start ? res?.data?.[0]?.window_start : startDate.toISOString(),
                toDate: res?.data?.[0]?.window_end ? res?.data?.[0]?.window_end : res?.data?.[0]?.time_utc_end,
            });
        };

        fetchData({ retry: true });
    },[selectedTimestamp]);

    useEffect(() => {
        if (selectedTimestamp) {
            const date = new Date((timestamps?.[selectedTimestamp] || monthlySummary?.published));

            const fourWeeksInMilliseconds = 4 * 7 * 24 * 60 * 60 * 1000;
            date.setTime(date.getTime() - fourWeeksInMilliseconds);

            setStartDate(date.toISOString());
        }
    }, [selectedTimestamp])

    return (
        <SummaryContainer>
            <Header style={{ fontSize: 24 }}>
                Monthly Summary For {expandedViewTicker}
            </Header>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {selectedTimestamp !== 0 &&
                        <StyledButton onClick={handleBack} style={{ margin: 0 }}>
                            {'<'}
                        </StyledButton>
                    }
                    {monthlySummary?.published && (
                        <b>
                            <StyledParagraph style={{ margin: '0 10px' }}>{formatDateYear(monthlySummary.fromDate)} - {formatDateYear((timestamps?.[selectedTimestamp] || monthlySummary?.toDate))}</StyledParagraph>
                        </b>
                    )}
                    {((selectedTimestamp === 0 || selectedTimestamp) && selectedTimestamp !== timestamps?.length - 1) &&
                        <StyledButton onClick={handleForward} style={{ margin: 0 }}>
                            {'>'}
                        </StyledButton>
                    }
                </div>
            {monthlySummary?.summaryLines?.length === 1 ?
                <StyledParagraph>
                    {monthlySummary?.summaryLines?.[0]}
                </StyledParagraph> :
                <SummaryWrapper>
                        <StyledParagraph style={{ fontWeight: 900 }}>
                                {monthlySummary?.summaryLines?.[0]?.split('### ').length > 1 ?
                                    monthlySummary?.summaryLines?.[0]?.split('### ')[1] :
                                    monthlySummary?.summaryLines?.[0]?.split('### ')[0]
                                }
                        </StyledParagraph>
                        <StyledParagraph>
                            <ul>
                                {monthlySummary?.summaryLines?.[1]?.split('$$ ').length === 1 ?
                                    monthlySummary?.summaryLines?.[1]?.split('** ')
                                        .map((line, index) => (
                                            line === '' ?
                                                null :
                                                <>
                                                    <li key={index} style={{ marginBottom: 5}} >
                                                        {line}
                                                    </li>
                                                </>
                                        )) : 
                                    monthlySummary?.summaryLines?.[1]?.split('$$ ')
                                        .map((line, index) => (
                                            line === '' ?
                                                null :
                                                <>
                                                    <li key={index} style={{ marginBottom: 5}} >
                                                        {line}
                                                    </li>
                                                </>
                                        ))
                                }
                            </ul>
                        </StyledParagraph>
                        {monthlySummary?.summaryLines?.slice(2).map((line, index) => (
                            <StyledParagraph key={index}>
                                {line}
                            </StyledParagraph>
                        ))}
                </SummaryWrapper>
            }
            <MarketReaderWatermark style={{ height: '30px' }}/>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                <div></div>
                <IconBox onClick={handleCopyClick} title='Copy to Clipboard'>
                    <CopyIcon selected={isCopied} />
                </IconBox>
            </div>
        </SummaryContainer>
    );
};

export default MonthlySummary;
