import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    CheckboxItem,
    CheckboxLabel,
    StyledLabel
} from '../StyledComponents/UserFormStyledComponents';

const CheckboxListWrapper = styled.div`
    width: 100%;
`;

const CheckboxList = ({ handleChange, id, label, options, value }) => {
    const [selectedOptions, setSelectedOptions] = useState(() => options.filter(option => value.split(id === 'excludedExplanations' ? ' AND ' : ' OR ').includes(option.value)));

    useEffect(() => {
        setSelectedOptions(options.filter(option => value.split(id === 'excludedExplanations' ? ' AND ' : ' OR ').includes(option.value)));
    }, [value])

    const handleSelect = option => {

        if (selectedOptions.some(selected => selected.value === option.value)) {
            setSelectedOptions(prev => {
                const newState = prev.filter(o => o.value !== option.value);
                handleChange(id, newState);

                return newState;
            });
        } else {
            setSelectedOptions(prev => {
                const newState = [...prev, option];
                handleChange(id, newState);

                return newState;
            });
        }
    };

    return (
        <CheckboxListWrapper>
            {label ?
                <StyledLabel>
                    {label}:
                </StyledLabel> :
                null
            }
            {options.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            }).map(option => (
                <CheckboxItem
                    checked={selectedOptions.some(o => o.value === option.value)}
                    key={option.value}
                    onClick={() => handleSelect(option)}
                >
                    <input 
                        type="checkbox"
                        id={option.value}
                        checked={selectedOptions.some(o => o.value === option.value)}
                        readOnly
                    />
                    <CheckboxLabel>{option.label}</CheckboxLabel>
                </CheckboxItem>
            ))}
        </CheckboxListWrapper>
    );
};

export default CheckboxList;
