import { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import SampleCard from "./SampleCard";
import styled from "styled-components";
import { AppColors } from '../../../Global/StyledComponents/Graphics';
import supabase from "../../../Controllers/utils/supabaseClient";

const SampleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-Y: scroll;
    padding-bottom: ${(!isMobile || isTablet) ? 0 : '20px'};

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

const SampleGrid = styled.div`
    display: flex;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: start;
`;

const SampleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${(!isMobile || isTablet) ? '60%' : '85%'};
    height: ${(!isMobile || isTablet) ? 'calc(100vh - 30px)' : null};
    padding-bottom: 40px;
`;

const Heading = styled.h1`
    color: ${AppColors.MRGREEN};
    font-size: 36px;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-weight: 600;
`;

const SubHeading = styled.p`
    color: white;
    font-size: 18px;
    max-width: 80%;
    margin: 10px;
`;

const StyledLink = styled.a`
    color: ${AppColors.MRGREEN};
    font-size: 18px;
`;

const SampleView = ({ readOnly }) => {
    const [samples, setSamples] = useState([]);

    useEffect(() => {
        supabase.from('sample_cards').select('*').then(res => {
            if (res?.data?.length) {
                const data = res?.data;
    
                setSamples(() => (
                    window.innerHeight < 1100 ?
                    data.slice(0, 4) :
                    data
                ));
            }
        });
    }, []);

    return (
        <SampleWrapper>
            <SampleContainer>
                <Heading>
                    Know What's Moving. Understand Why.
                </Heading>
                <SubHeading>
                    Real-time explanations of today's most unusual asset moves:
                </SubHeading>
                <SampleGrid>
                    {samples?.map((sampleData) => (
                        <SampleCard data={sampleData} readOnly={readOnly} />
                    ))}
                </SampleGrid>
                <SubHeading>
                    MarketReader leverages proprietary financial modeling and generative AI technology to provide concise explanations of market movements as they happen.
                </SubHeading>
                <SubHeading>
                    MarketReader users have access to timely, comprehensive and easily digestible summaries of the drivers of price movement across 11,000 US-listed equity assets, with more asset classes and geographies coming soon.
                </SubHeading>
                <br/>
                <StyledLink href="https://marketreader.com/technology/" target="blank">
                        Learn More
                </StyledLink> 
            </SampleContainer>
        </SampleWrapper>
    );
};

export default SampleView;
