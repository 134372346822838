import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { formatLineData, formatVolume, formatScatter, formatExtendedHours, formatTooltipVolume, generateRthPlotBands } from './support';
import { formatDateTime, formatDateYear } from '../../../../../Controllers/utils';
import { ChartContainer } from '../../../../StyledComponents/AppStyledComponents';
import './support/index.css'

HighchartsBoost(Highcharts);
HighchartsAccessibility(Highcharts);

const StockChart = ({ 
    carouselPlotLine,
    chartData, 
    height, 
    hoverPoint, 
    loadMoreData, 
    markerData, 
    setChartMinMax, 
    setClickedPoint, 
    setHoverPoint, 
    symbol, 
    width,
}) => {

    const chartRef = useRef(null);
    const [isScatter, setIsScatter] = useState(false);
    const [options, setOptions] = useState({
        chart: {
            backgroundColor: '#0a1926',
            height: height - 300,
        },
        credits: {
            text: '',
            href: '',
        },
        yAxis: [
            {
                gridLineColor: 'transparent',
                labels: {
                    style: {
                        color: 'white',
                    },
                    align: 'center',
                    y: 5,
                },
                top:'10%',
                height: '80%',
                resize: {
                    enabled: true,
                },
                crosshair: {
                    snap: false,
                },
            },
            {
                gridLineColor: 'transparent',
                labels: {
                    style: {
                        color: 'transparent',
                    },
                },
                title: {
                    text: '',
                    style: {
                        color: 'transparent',
                    },
                },
                top: '70%',
                height: '30%',
            },
        ],
        series: [
            {
                name: '',
                data: [],
                color: chartData.navigator === '10m' ? '#4f4f40' : '#E1D9D1',
                type: 'line',
                yAxis: 0,
                enableMouseTracking: true,
                dashStyle: chartData.navigator === '10m' ? 'ShortDash' : 'Solid',
            },
            {
                name: '',
                type: 'column',
                id: `${symbol}-volume`,
                color: '#5DD39E',
                data: [],
                yAxis: 1,
            },
            {
                type: 'scatter',
                id: `${symbol}-scatter`,
                data: [],
                yAxis: 0,
                marker: {
                    symbol: 'circle',
                },
                zIndex:5,
                point: {
                    events: {
                        mouseOver: function () {
                            setHoverPoint({ ts: this.x });
                        },
                        click: function () {
                            handleClick(this.x);
                        },
                    },
                },
            },
            {
                name: '',
                data: [],
                color: chartData.navigator === '10m' ? '#E1D9D1' : '#4f4f40',
                type: 'line',
                yAxis: 0,
                enableMouseTracking: true,
            },
        ],
        rangeSelector: {
            enabled: false,
        },
        navigator: {
            enabled: true,
            adaptToUpdatedData: true,
            series: {
                name: '',
                color: '#4f4f4f'
            },
        },
        scrollbar: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            split: false,    
            positioner: function (labelWidth, labelHeight, point) {
                let chart = this.chart;
                let tooltipX = point.plotX - labelWidth / 2; 
                let tooltipY;
            
                if (point.plotY < labelHeight + 20) {
                    tooltipY = chart.plotTop + chart.plotHeight - 25;
                } else {
                    tooltipY = chart.plotTop + 10;
                }
            
                if (tooltipX + labelWidth > chart.plotWidth) {
                    tooltipX = chart.plotWidth - labelWidth;
                } else if (tooltipX < 0) {
                    tooltipX = 0;
                }
            
                return {
                    x: tooltipX + chart.plotLeft, 
                    y: tooltipY
                };
            },
            
            borderWidth: 0,
            backgroundColor: isScatter ? 'transparent' :  '#0c2233',
            shadow: false,
            style: {
                color: 'white',
                fontSize: '10px',
            },
            formatter: function () {
                if(!this?.points) {
                    setIsScatter(true);

                    return false;
                }
                let tooltipData = this?.points.filter(point => point?.series.options.id === 'stockPrice') || null;

                if (tooltipData?.length === 0) {

                    return false;
                }
                setIsScatter(false);
                let point = tooltipData[0].point.custom;
                const pointDate = chartData.navigator === 'D' ? formatDateYear(point.time_utc) : formatDateTime(point.time_utc);
                const formattedVolume = formatTooltipVolume(point.volume);


                return `<span>$${point.close} ${formattedVolume} ${pointDate}</span>`;

            },
            enabled: true,
        },
        plotOptions: {
            series: {
                stickyTracking: false,
                turboThreshold: 5000,
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                dataGrouping: {
                    enabled: false
                }
            },
            line: {
                stickyTracking: true,
            },
            scatter: {
                marker: {
                    states: {

                        select: {
                            fillColor: 'grey',
                            lineColor: 'white',
                            lineWidth: 2,
                            radius: 7,
                        },
                    },
                },

            },
            column: {
                opacity: 0.7,
                states: {
                    hover: {
                        opacity: 0.7,
                    },
                    inactive: {
                        opacity: 0.7
                    }
                },
            },
        },
        xAxis: {
            crosshair: {
                snap: false,
            },
            events: {
                afterSetExtremes: async function (e) {
                    setChartMinMax({ min: e.min, max: e.max });
                    const chart = this.chart;
                    const min = e.min;
                    const max = e.max;
                    const dataExtremes = this.getExtremes();

                    if (chartData?.navigator === 'D') {
                        if (min <= dataExtremes.dataMin) {
                            const newData = await loadMoreData(min);
                            const formattedLineData = formatLineData(newData);
                            const formattedVolumeData = formatVolume(newData);

                            const lineData = [...chart.series[0].options.data, ...formattedLineData];
                            const volumeData = [...chart.series[1].options.data, ...formattedVolumeData];
                            const scatterData = chart.series[2].options.data;

                            lineData.sort((a, b) => a.x - b.x);
                            volumeData.sort((a, b) => a.x - b.x);

                            setOptions((prevOptions) => ({
                                ...prevOptions,
                                series: [
                                    {
                                        data: lineData,
                                        id: 'stockPrice',
                                        tooltip: {
                                            valueDecimals: 2,
                                        },
                                        type: 'line',
                                        yAxis: 0,
                                    },
                                    {
                                        type: 'column',
                                        id: `${symbol}-volume`,
                                        data: volumeData,
                                        yAxis: 1,
                                        color: '#5DD39E',
                                    },
                                    {
                                        type: 'scatter',
                                        data: scatterData,
                                        yAxis: 0,
                                        marker: {
                                            symbol: 'circle',
                                        },
                                    }
                                ],
                            }));
                        }
                    }
                },
                setExtremes: function (e) {
                    setHoverPoint(null);
                },
            },
            labels: {
                style: {
                    color: 'white',
                },
            },
        },
        boost: {
            enabled: true,
        },
        time:{
            useUTC:false,
        }
    });

    useEffect(() => {
        if (chartData) {
            chartRef.current.chart.showLoading();
            const formattedLineData = formatLineData(chartData.data);
            const formattedVolumeData = formatVolume(chartData.data);
            const formattedExtendedHours = chartData.navigator === '10m' ? formatExtendedHours(chartData.data) : [];

            let pastDateUTC;
            const date = new Date();

            if (chartData.navigator === 'D') {
                date.setDate(date.getDate() - 180);
                pastDateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
            } else {
                date.setDate(date.getDate() - 3);
                pastDateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
            }
            setOptions((prevOptions) => ({
                ...prevOptions,
                series: [
                    {
                        data: formattedLineData,
                        id: 'stockPrice',
                        tooltip: {
                            valueDecimals: 2,
                        },
                        type: 'line',
                        yAxis: 0,
                    },
                    {
                        type: 'column',
                        id: `${symbol}-volume`,
                        data: formattedVolumeData,
                        yAxis: 1,
                        color: '#5DD39E',
                    },
                    {
                        type: 'scatter',
                        data: [],
                        yAxis: 0,
                        marker: {
                            symbol: 'circle',
                        },
                    },
                    {
                        name: '',
                        data: formattedExtendedHours,
                        type: 'line',
                        yAxis: 0,
                        connectNulls: false,
                        allowPointSelect: false,
                    },
                ],
                xAxis: {
                    ...prevOptions.xAxis,
                   min: pastDateUTC,
                },
            }));
            chartRef.current.chart.hideLoading();

        }
    }, [chartData]);

    useEffect(() => {
        if (markerData) {
            const scatterData = formatScatter(markerData, chartData.data);
            if(scatterData.length){
                const lastScatterItem = scatterData[scatterData.length - 1];
                setHoverPoint({ ts: lastScatterItem.x });
            }
            const formattedLineData = formatLineData(chartData.data);
            const formattedVolumeData = formatVolume(chartData.data);

            const formattedExtendedHours = chartData.navigator === '10m' ? formatExtendedHours(chartData.data) : [];
            const formattedPlotBands = chartData.navigator === '10m' ? generateRthPlotBands(chartData.data) : [];            
            
            scatterData.sort((a, b) => b.ts - a.ts);
            formattedExtendedHours.sort((a, b) => b.ts - a.ts);

            setOptions((prevOptions) => ({
                ...prevOptions,
                series: [
                    {
                        data: formattedLineData,
                        id: 'stockPrice',
                        tooltip: {
                            valueDecimals: 2,
                        },
                        type: 'line',
                        yAxis: 0,
                    },
                    {
                        data: formattedVolumeData,
                        type: 'column',
                        id: `${symbol}-volume`,
                        yAxis: 1,
                    },
                    {
                        type: 'scatter',
                        data: scatterData,
                        marker: {
                            symbol: 'circle',
                        },
                        yAxis: 0,
                    },
                    {
                        data: formattedExtendedHours,
                        id: 'stockPrice3',
                        tooltip: {
                            valueDecimals: 2,
                        },
                        type: 'line',
                        yAxis: 0,
                        zIndex:1,
                        connectingNulls:true,
                    },
                ],
                xAxis: {
                    ...prevOptions.xAxis,
                    plotBands: formattedPlotBands
                },
            }));

        }
    }, [markerData]);

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            const dataIndex = chart.series[2].data.findIndex((point) => point.x === hoverPoint?.ts);
            const selectPoint = chart.series[2].data[dataIndex];
            const scatterSeries = chart.series[2];

            if (selectPoint) {
                scatterSeries.data.forEach(function (point) {
                    if (point === selectPoint) {
                        point.select(true, false);
                    } else {
                        point.select(false, true);
                    }
                });
            }

            setOptions((prevOptions) => {
                const newXAxis = { ...prevOptions.xAxis };
                if (hoverPoint) {
                    newXAxis.plotLines = [
                        {
                            value: hoverPoint.ts,
                            color: 'grey',
                            width: 2,
                            id: 'dynamicPlotLine',
                            dashStyle: 'dash',
                        },
                    ];
                } 
                else {
                    newXAxis.plotLines = (newXAxis.plotLines || []).filter(
                        (line) => line.id !== 'dynamicPlotLine'
                    );
                }

                return {
                    xAxis: newXAxis,
                };
            });
        }
    }, [hoverPoint]);

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            const dataIndex = chart.series[2].data.findIndex((point) => point.x === carouselPlotLine?.ts);
            const selectPoint = chart.series[2].data[dataIndex];
            const scatterSeries = chart.series[2];

            if (selectPoint) {
                scatterSeries.data.forEach(function (point) {
                    if (point === selectPoint) {
                        point.select(true, false);
                    } else {
                        point.select(false, true);
                    }
                });
            }

            setOptions((prevOptions) => {
                const newXAxis = { ...prevOptions.xAxis };
                if (carouselPlotLine) {
                    newXAxis.plotLines = [
                        {
                            value: carouselPlotLine.ts,
                            color: 'grey',
                            width: 2,
                            id: 'dynamicPlotLine',
                            dashStyle: 'dash',
                        },
                    ];
                } 
                else {
                    newXAxis.plotLines = (newXAxis.plotLines || []).filter(
                        (line) => line.id !== 'dynamicPlotLine'
                    );
                }

                return {
                    xAxis: newXAxis,
                };
            });
        }
    }, [carouselPlotLine]);

    const handleClick = (point) => {
        setClickedPoint(point);
        setTimeout(() => {
            setClickedPoint(null);
        }, 500);
    };

    useEffect(() => {
        chartRef.current.chart.update({
            chart: {
                width: width,
            }
        })
    }, [width])

    return (
        <ChartContainer
            style={{ width: `${width}px`}}
        >
            <HighchartsReact
                containerProps={{ style: { width: `${width}px`}}}
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={options}
                ref={chartRef}
            />
        </ChartContainer>
    );
};

export default StockChart;
