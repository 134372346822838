import { useEffect, useState, useContext, useRef } from 'react';
import { isMobile, isTablet } from "react-device-detect";
import styled from 'styled-components';

import { StateContext } from '../../Controllers/context/state';

import Drawer from '../../Global/Components/Drawer';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import ExportToCsv from '../../Global/Components/ExportToCsv';
import FeedViewer from "../../Global/Views/FeedViewer/FeedViewer";
import { Header, StyledLabel } from '../../Global/StyledComponents/UserFormStyledComponents';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import {

    Line,
    ViewModule,
} from "../../Global/StyledComponents/AppStyledComponents";
import StockCardView from '../../Global/Views/StockCardView/StockCardView';
import supabase from '../../Controllers/utils/supabaseClient';
import StockCardGridComponent from '../../Global/Layouts/StockCardGrid';
import { FeedItem, Timestamp } from '../../Global/Views/FeedViewer/components/NewsFeed';
import { formatDate, formatDateYear, formatTime } from '../../Controllers/utils';
import moment from 'moment';
import { AppColors } from '../../Global/StyledComponents/Graphics';

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    min-width: 275px;
    padding-top: 15px;
`;

const MacroContainer = styled.div`
    height: 100%;
    overflow: auto; 
    max-width: 392px;
    border: 1px solid ${AppColors.MRNAVY40};

    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

const DashboardMonitor = () => {
    const afterhoursFilter = {
        marketReaderHot: "(date_return_oc_percentile NOT BETWEEN 0.06 AND 0.94) OR (date_range_hl_percentile > 0.95)",
        marketCap: "mcap_mm>500",
        assetType: "type='Common Stock' OR type='ADR'",
        sameDay: "market_date > (SELECT CASE WHEN date_trunc('day', NOW()) = date_trunc('day',market_open) THEN date ELSE date_trunc('day',market_close_prev) END as ref_date FROM market_calendar_ref mcr where market = 'NYSE' and NOW() BETWEEN market_close_prev and market_close)",
        highImpact: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.135 ELSE false END", 
        summary: "dtm_summary NOTNULL"
    };
    const afterhoursRef = useRef();
    const [afterhoursTickerList, setAfterhoursTickerList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingAfterhours, setLoadingAfterhours] = useState(true);
    const sameDayFilter = {
        marketReaderHot: "(date_return_oc_percentile NOT BETWEEN 0.06 AND 0.94) OR (date_range_hl_percentile > 0.95)",
        marketCap: "mcap_mm>500",
        assetType: "type='Common Stock' OR type='ADR'",
        sameDay: "market_date = (SELECT CASE WHEN date_trunc('day', NOW()) = date_trunc('day',market_open) THEN date ELSE date_trunc('day',market_close_prev) END as ref_date FROM market_calendar_ref mcr where market = 'NYSE' and NOW() BETWEEN market_close_prev and market_close)",
        highImpact: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.135 ELSE false END", 
        summary: "dtm_summary NOTNULL"
    };
    const [tickerList, setTickerList] = useState([]);


    const {
        development,
        expandedViewTicker,
        isLoading,
        logError,
        macroSummaries,
        macroSummary,
        setSort,
        setShowErrorWindow,
        setShowWelcomeWindow,
        showExpandedView,
        sort,
    } = useContext(StateContext);

    const createFeedItems = () => {
        if (development) {
            return (
                macroSummaries?.map((item, index) => (
                    <>
                        <FeedItem key={item.type + index} >
                            <p style={{ margin: 0 }}>
                                <b>[{formatTime(item.time_utc)}]: </b>
                                {item.data.summary.split('\n').map((paragraph) => (
                                    <>
                                        {paragraph}
                                        <br />
                                    </>
                                ))}&nbsp;
                                <Timestamp>({moment(item.time_utc).fromNow()})</Timestamp>
                            </p>
                        </FeedItem>
                        <Line />
                    </>
                ))
            );
        }

        return (
            <>
                <FeedItem>
                    <p style={{ margin: 0 }}>
                        <b>[{formatTime(macroSummary?.time_utc)}]: </b>
                        {macroSummary?.data?.summary.split('\n').map((paragraph) => (
                            <>
                                {paragraph}
                                <br />
                            </>
                        ))}&nbsp;
                        <Timestamp>({moment(macroSummary?.time_utc).fromNow()})</Timestamp>
                    </p>
                </FeedItem>
                <Line />
            </>
        );
    };

    const fetchTickerList = ({ retry = true }) => {
        supabase.rpc('getfilteredsortedlist', { request: {
            filter: sameDayFilter,
            sort,
        }}).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    fetchTickerList({ retry: false });
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }
    
                return;
            }

            const { entities } = res?.data?.[0]?.symbol;
            setTickerList(entities);
            setLoading(false);
        });
    };

    const fetchAfterhoursTickerList = ({ retry = true }) => {
        supabase.rpc('getfilteredsortedlist', { request: {
            filter: afterhoursFilter,
            sort,
        }}).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    fetchAfterhoursTickerList({ retry: false });
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }
    
                return;
            }

            const { entities } = res?.data?.[0]?.symbol;
            setAfterhoursTickerList(prevState => {
                if (entities?.length > prevState?.length &&
                    entities?.length * 342.5 > afterhoursRef?.current?.clientWidth
                ) {
                    setLoading(true);
                    setTimeout(() => setLoading(false), 500);
                }
                return entities;
            });
            setLoadingAfterhours(false);
        });
    };

    useEffect(() => {
        if (!isLoading) {
            fetchTickerList({ retry: true });
            fetchAfterhoursTickerList({ retry: true });
        }
    }, [isLoading, sort]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchTickerList({ retry: true });
            fetchAfterhoursTickerList({ retry: true });
        }, 180000);
        setSort({
            asc: false,
            abs: true,
            key: 'date_return_oc_voladj',
        });
        
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        supabase.from('profiles').select('welcome_page').then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                return;
            }
            setShowWelcomeWindow(res?.data[0]?.welcome_page && (!isMobile || isTablet));
        });
    }, []);

    if (isLoading) {
        return (
            <ViewModule>
                <Loading />
            </ViewModule>
        );
    }
    
    return (
        <>
            <ViewModule>
                {(!isMobile || isTablet) ?
                        <div style={{ height: '100%', display: 'flex' }}>
                            <div style={{ position: 'absolute', top: 20, right: 65 }}>
                                <ExportToCsv />
                            </div>
                            <SectionWrapper>
                                <Header style={{ margin: 5, marginTop: -18 }}>
                                    <div>
                                        Current Macro Overview
                                    </div>
                                    <div>
                                        {formatDate(macroSummary?.time_utc || macroSummaries?.[0].time_utc)}
                                    </div>
                                </Header>
                                <br />
                                <MacroContainer>
                                    {createFeedItems()}
                                </MacroContainer>
                            </SectionWrapper>
                            <SectionWrapper style={{ width: '70%' }}>
                                <Header style={{ margin: 5, marginTop: -10 }}>
                                    <div>
                                        Current Unusual Moves
                                    </div>
                                    <div>
                                        US Stocks/ADRs
                                    </div>
                                </Header>
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 48px' }}>
                                    {loading ?
                                        <Loading /> :
                                        <>
                                            <div style={{ flex: '0 1 auto', maxHeight: '50%' }} ref={afterhoursRef}>
                                                {afterhoursTickerList?.length ?
                                                    <>
                                                        <StyledLabel style={{ fontWeight: 'bold', margin: 5, marginLeft: 10 }}>
                                                            Afterhours Movers
                                                        </StyledLabel>
                                                        <StockCardGridComponent height={`${afterhoursRef?.current?.clientHeight - 34}px`} >
                                                            {loadingAfterhours ?
                                                                <Loading /> :
                                                                <StockCardView tickerList={afterhoursTickerList} />
                                                            }
                                                        </StockCardGridComponent>
                                                    </> :
                                                    null
                                                }
                                            </div>
                                            <div style={{ flex: '1 1 auto', height: `calc(100% - ${afterhoursRef?.current?.clientHeight}px)`}}>
                                                <StyledLabel style={{ fontWeight: 'bold', margin: 5, marginLeft: 10 }}>
                                                    {formatDateYear(new Date().getTime())}
                                                </StyledLabel>
                                                <StockCardGridComponent height={`calc(100% - 34px)`} >
                                                        <StockCardView tickerList={tickerList} />
                                                </StockCardGridComponent>
                                            </div>
                                        </>
                                    }
                                </div>
                            </SectionWrapper>
                        </div> :
                        <>
                            <Header style={{ margin: 5 }}>
                                <div>
                                    Current Unusual Moves
                                </div>
                                <div>
                                    US Stocks/ADRs
                                </div>
                            </Header>
                            <br />
                            <StockCardGridComponent >
                                <StockCardView tickerList={tickerList} />
                            </StockCardGridComponent> 
                        </>
                }
            </ViewModule>
            <Drawer open={showExpandedView} rotate={'true'} >
                <FeedViewer open={(!isMobile || isTablet)} tickerList={tickerList} title='News Feed'/>
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                <ExpandedView title={`${expandedViewTicker} Summary`}/>
            </Drawer>
        </>
    )
}

export default DashboardMonitor;
