import { NavLink } from "react-router-dom";
import { HelpIcon, MenuIcon } from "../../../Icons/Icons";
import { OpenArrowUpIcon } from "../../../Icons/Icons";
import { isMobile, isTablet } from "react-device-detect";
import { DropdownMenu, NavivationItem } from "../StyledComponents";

const NavMenu = ({
    handleMouseLeave,
    handleSupport,
    handleToggleNavMenu,
    navMenuOpen,
}) => {

    return (
        <NavivationItem
            className="dropdown-toggle"
            onClick={handleToggleNavMenu}
            onMouseLeave={handleMouseLeave}
        >
            <MenuIcon />
            {(!isMobile || isTablet) &&
                <>
                    &nbsp;
                    Menu
                </>
            }
            {navMenuOpen && (
                <DropdownMenu
                    onMouseLeave={handleMouseLeave}
                >
                    <ul onClick={handleToggleNavMenu}>
                        <li>
                            <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/">
                                {(!isMobile || isTablet) ?
                                    'Dashboard' :
                                    'Unusual Moves'
                                }
                            </NavLink>
                        </li>
                        {(!isMobile || isTablet) ?
                            <li>
                                <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/unusualMoves">Unusual Moves</NavLink>
                            </li> : null
                        }
                        <li>
                            <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/etf">ETF Monitor</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/macro">Macro Monitor - Beta</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/news">Market-Wide News</NavLink>
                        </li>                            
                        <li>
                            <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/screener">Smart Screener</NavLink>                            
                        </li>
                        <li>
                            <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/watchlists">Watchlists</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={handleToggleNavMenu} className="dropdown-item" to="/faqs">Knowledge and Support</NavLink>
                        </li>
                        <li>
                            <div style={{ fontSize: '14px' }} className="dropdown-item" onClick={handleSupport}>
                                Feedback/Bug Report
                                <HelpIcon />
                            </div>
                        </li>
                        <li>
                            <OpenArrowUpIcon
                                className="dropdown-close"
                                onClick={handleToggleNavMenu}                                />
                        </li>
                    </ul>
                </DropdownMenu>
            )}
        </NavivationItem>
    )
};

export default NavMenu;
