import { useContext } from "react";
import { StateContext } from "../../Controllers/context/state";
import { FilterContainer } from "../StyledComponents/AppStyledComponents";
import { SortOn, SortOrder, SortOrderStrings, StringSortItems } from "../constants";
import DropdownMenu from "./DropdownMenu";
import { isMobile, isTablet } from 'react-device-detect';

const SortBar = ({
    firstPage
}) => {
    const {
        setSort,
        sort,
    } = useContext(StateContext);

    const handleSortOn = (e) => {
        const value = e.target.value;
        
        setSort(prevState => ({
            ...prevState,
            asc: false,
            abs: false,
            key: value,
        }));
        firstPage();
    };

    const handleSortOrder = (e) => {
        const value = JSON.parse(e.target.value);

        setSort((prevState) => ({
            ...prevState,
            ...value,
        }));
        firstPage();
    };

    return (
        <FilterContainer style={{ maxWidth: '650px', width: !isMobile || isTablet ? '100%' : '95%' }}>
            <div style={{ width: '50%' }}>
                <DropdownMenu
                    id='sortOn'
                    inline
                    label={SortOn.label}
                    handleChange={handleSortOn}
                    options={SortOn.values}
                    value={sort.key}
                />
            </div>
            <div style={{ marginLeft: '10px', width: 'calc(50% - 10px)' }}>
                <DropdownMenu
                    id='sortOrder'
                    inline
                    label={SortOrder.label}
                    handleChange={handleSortOrder}
                    options={StringSortItems.includes(sort.key) ? SortOrderStrings.values : SortOrder.values}
                    value={{asc: sort.asc, abs: sort.abs}}
                />
            </div>
        </FilterContainer>
    );
};

export default SortBar;
