import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../../Controllers/utils/supabaseClient';
import {
    Container,
    FormWrapper,
    SubmitButton,
    Form,
    Header,
    Input,
    SuccessMessage,
    WarningText
} from '../../Global/StyledComponents/UserFormStyledComponents';
import { StyledButton } from '../../Global/StyledComponents/UserFormStyledComponents';
import { WhiteTextGlassLogo } from '../../Global/Logos/Logos';

function ForgotPasswordMonitor() {
    const [email, setEmail] = useState();
    const [requestSent, setRequestSent] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false)
    const navigate = useNavigate();


    const handleChange = (e) => {
        setEmail(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        supabase.auth.resetPasswordForEmail(email)
            .then((res) => {
                const { error } = res;

                if (error) {
                    setRequestFailed(true);
                } else {
                    setRequestFailed(false);
                    setRequestSent(true);
                }
            });
    };

    return (
        <Container >
            <StyledButton 
                onClick={() => navigate('/login')}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: '10px',
                    left: '20px',
                }}
            >
                Back to Login
            </StyledButton>
            {!requestSent ? (
                <FormWrapper>
                    <WhiteTextGlassLogo title="Market Reader logo" />
                    <Header>Send Reset Password Request</Header>
                    <Form onSubmit={handleSubmit}>
                        <Input
                            className='login'
                            autoComplete='email'
                            id='email'
                            type="email"
                            onChange={handleChange}
                            placeholder='Your Email'
                        />
                        <SubmitButton type="submit">Send Request</SubmitButton>
                        {requestFailed && 
                            <WarningText>Request failed! Check email and try again.</WarningText>
                        }
                    </Form>
                </FormWrapper>) : (
                    <FormWrapper>
                        <SuccessMessage>
                            Password reset request sent successfully. Check your email and follow the instructions to reset your password.
                        </SuccessMessage>
                        <SubmitButton onClick={handleSubmit}>Resend Reset Request</SubmitButton>
                        {requestFailed && 
                            <WarningText>Request failed! Wait 60 seconds to try again.</WarningText>
                        }
                    </FormWrapper>
            )}
        </Container>
    );
}
export default ForgotPasswordMonitor;
