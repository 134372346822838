import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Loading from '../../../Global/Components/LoadingSpinner';
import { StateContext } from '../../../Controllers/context/state';
import supabase from '../../../Controllers/utils/supabaseClient';
import ExpandedView from '../../../Global/Components/ExpandedView/ExpandedView';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 15px;
	position: relative;
	overflow: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
		overflow: hidden;
	}
`;

const ChartInfoWindow = ({ setShowChartInfoWindow }) => {
	const [windowData, setWindowData] = useState(null);
	const [loading, setLoading] = useState(true);
	const { chartInfo, logError, sort } = useContext(StateContext);

	const fetchData = async ({ retry = true }) => {
		let data;

		const res = await supabase.rpc('get_expanded_summary_for_chart', {
			_symbol: chartInfo.symbol,
			_aggr: chartInfo.aggr,
			_epoch: chartInfo.ts / 1000,
			_corrasset: sort.correlationAsset,
		});

		if (res?.error) {
			const error = new Error(res?.error?.message);
			if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
				fetchData({ retry: false });
				return;
			}
            logError(error);
		}
		if (res?.data?.length) {
			data = res?.data?.[0]?.data_row;
		}

		setWindowData(data);
		setLoading(false);
	};

	useEffect(() => {
		if (chartInfo?.aggr) {
			fetchData({ retry: true });
			return;
		}
		setWindowData(chartInfo);
		setLoading(false);
	}, [chartInfo]);

	if (loading) {
		return (
			<Container>
				<Loading />
			</Container>
		);
	}

	return (
		<Container>
			<ExpandedView showInfo data={windowData} />
		</Container>
	);
};

export default ChartInfoWindow;
