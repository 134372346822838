import { useContext } from "react";
import moment from 'moment';

import { Line } from '../../StyledComponents/AppStyledComponents';
import {
    FeedContainer,
    FeedItem,
    Timestamp
} from "./components/NewsFeed";
import { formatTime } from "../../../Controllers/utils";
import { StateContext } from "../../../Controllers/context/state";
import { Header } from "../../StyledComponents/UserFormStyledComponents";

const MacroSummariesFeed = () => {
    const {
        development,
        macroSummaries,
        macroSummary,
    } = useContext(StateContext);

    const createFeedItems = () => {
        if (development) {
            return (
                macroSummaries?.map((item, index) => (
                    <>
                        <FeedItem key={item.type + index} >
                            <p style={{ margin: 0 }}>
                                <b>[{formatTime(item.time_utc)}]: </b>
                                {item.data.summary.split('\n').map((paragraph) => (
                                    <>
                                        {paragraph}
                                        <br />
                                    </>
                                ))}&nbsp;
                                <Timestamp>({moment(item.time_utc).fromNow()})</Timestamp>
                            </p>
                        </FeedItem>
                        <Line />
                    </>
                ))
            );
        }

        return (
            <>
                <FeedItem>
                    <p style={{ margin: 0 }}>
                        <b>[{formatTime(macroSummary?.time_utc)}]: </b>
                        {macroSummary?.data?.summary.split('\n').map((paragraph) => (
                            <>
                                {paragraph}
                                <br />
                            </>
                        ))}&nbsp;
                        <Timestamp>({moment(macroSummary?.time_utc).fromNow()})</Timestamp>
                    </p>
                </FeedItem>
                <Line />
            </>
        );
    };

    return (
        <div style={{ height: 'calc(100% - 45px)' }}>
            <Header style={{ margin: 5 }}>
                Macro Overview
            </Header>
            <br />
            <FeedContainer  title='Macro Overview'>
                {createFeedItems()}
            </FeedContainer>
        </div>
    )
};

export default MacroSummariesFeed;