import { useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import supabase from '../../Controllers/utils/supabaseClient';
import {
    FormWrapper,
    Form,
    Header,
    Input,
    MobileFormWrapper,
    StyledButton,
    StyledHyperLink,
    StyledLink,
    SubmitButton,
    SuccessMessage,
    WarningText,
    StyledLabel,
    SignUpContainer,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import { Plans, PromoCodeCopy } from '../../Global/constants';
import PlanView from '../PlanSelectionMonitor/components/PlanView';
import ToggleSlide from '../../Global/Components/ToggleSlide';
import { WhiteTextGlassLogo } from '../../Global/Logos/Logos';
import { AppColors } from '../../Global/StyledComponents/Graphics';
import HubSpotContactForm from './components/HubspotContactForm';
import SampleView from '../LoginMonitor/components/SampleView';

const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${(!isMobile || isTablet) ? '60%' : '85%'};
    height: ${(!isMobile || isTablet) ? '100vh' : null};
`;

const StyledList = styled.ul`
    height: auto;
    margin: 0;
    padding: 0 20px;
    padding-top: 20px;
`;

const SignUpMonitor = () => {
    const code = new URLSearchParams(useLocation().search).get('code');
    const [promoMessage, setPromoMessage] = useState(null);
    const containerRef = useRef(null);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeValid, setPromoCodeValid] = useState(false);
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(!firstName || !lastName || !email);
    const [success, setSuccess] = useState(false);
    const [token, setToken] = useState('');
    const [yearly, setYearly] = useState(false);

    useEffect(() => {
        setDisabled(!firstName || !lastName || !email)
    }, [firstName, lastName, email]);

    const handleApplyPromo = async ({ code, e }) => {
        e && e.preventDefault();
        setPromoMessage(null);

        const { data, error } = await supabase.functions.invoke('checkPromo', {
            body: {
                promoCode: code || promoCode,
            },
        });

        const { message, ok } = JSON.parse(data);

        if (error) {
            setPromoCodeValid(false);
            setPromoMessage({ message: 'There was an error applying your promo code. Please try again.' });

            return;
        }

        if (!ok) {
            setPromoCodeValid(false);
            setPromoMessage({ message: message });

            return;
        }

        if (ok) {
            setPromoCodeValid(true);
            setPromoMessage({ message: message });

            return;
        }
    };

    const handleChangePromoCode = (e) => {
        setPromoMessage(null);
        setPromoCode(e.target.value);
    };

    const handleChangeToken = (e) => {
        setToken(e.target.value);
    };

    const handleChangeYearly = () => {
        setYearly(prevState => !prevState)
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError(false);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleResend = () => {
        supabase.functions.invoke('sendInvite', {
            body: {
                userEmail: email.replace(/(\+.+@)/g, '@'),
            },
        });
    };

    const handleScroll = () => {
        containerRef?.current?.scrollIntoView({
            block: 'end',
            behavior: "smooth",
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { data, error } = await supabase.functions.invoke('createCustomerV2', {
            body: {
                firstName,
                lastName,
                userEmail: email.replace(/(\+.+@)/g, '@'),
                promoCode: promoCodeValid ? promoCode : null,
            },
        });

        if (error) {
            setError(true);
            setErrorMessage('There was an error creating the customer. Please try again.');
            setDisabled(false);

            return;
        }

        const { message, ok } = JSON.parse(data)
        
        if (ok) {
            rewardful('convert', { email: email.replace(/(\+.+@)/g, '@') });
            setSuccess(true);
            setDisabled(false);

            return;
        }

        if (!ok) {
            setError(true);
            setErrorMessage(message);
            setDisabled(false);

            return;
        }
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { data, error } = await supabase.auth.verifyOtp({ email: email, token: token, type: 'email'});

        if (data?.user?.role === 'authenticated') {
            navigate('/setPassword');
            setDisabled(false);

            return;
        }

        if (error) {
            setError(true);
            setErrorMessage(error.message);
            setDisabled(false);

            return;
        }
    };

    useEffect(() => {
        if (code) {
            setPromoCode(code);
            handleApplyPromo({ code });
        }
    }, [code]);

    useEffect(() => {
        containerRef?.current?.scrollIntoView({
            block: 'start',
        });
    }, [])

    return (
        <SignUpContainer code={code} isMobile={isMobile && !isTablet} ref={containerRef} signUp>
            <StyledButton
                onClick={() => navigate('/login')}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: 0,
                    left: '10px',
                }}
            >
                Back to Login
            </StyledButton>
            {(!isMobile || isTablet) && !code &&
                <SampleView />
                // <PriceWrapper>
                //     <div style={{ display: 'flex', flexDirection: (!isMobile || isTablet) ? 'row' : 'column',  }}>
                //         {Object.keys(Plans.monthly).map(key => (
                //             <PlanView plan={Plans.monthly[key]} readOnly />
                //         ))}
                //     </div>
                // </PriceWrapper>
            }
            <MobileFormWrapper code={code} isMobile={isMobile && !isTablet}>
                <FormWrapper isMobile={isMobile && !isTablet}>
                    <WhiteTextGlassLogo title="Market Reader logo" />
                    <Header>
                        Request Trial Access
                    </Header>
                    <br />
                    <HubSpotContactForm />
                </FormWrapper>
                <SuccessMessage>
                    Questions? Contact&nbsp;
                    <StyledHyperLink href = "mailto: support@marketreader.com">support@marketreader.com</StyledHyperLink>
                </SuccessMessage>
            </MobileFormWrapper>
        </SignUpContainer>
    );
};

export default SignUpMonitor;
