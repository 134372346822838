import styled from 'styled-components';
import { CardColors } from '../../Global/StyledComponents/Graphics';

export const ETFGrid = styled.div` 
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 1%;
    text-align: center;
    overflow-y: scroll;
    padding: 5px; 

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const ETFColumn = styled.div`
    display: grid;
    flex-direction: column;
    grid-column: span 1;
    gap: 10px;
    height:fit-content;
`;

export const ETFCardContainer = styled.div`
    background: ${props => CardColors[props.percentileMove]};
    max-height: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow-x: hidden;
    padding: 10px;
    display: block;

`;

export const TitleRow = styled.h1`
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    margin: 10px 8px;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ETFTopRow = styled.div`
    display: block;
    max-width: calc(100%);
    height: calc(50%);
    overflow: hidden; 
`;

export const ETFName = styled.h2`
    min-width: calc(70% - 15px);
    max-width: calc(100% - 15px);
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    padding: 0px; 
`;

export const ETFBottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: calc(100%);
    min-height: calc(50%);
    max-height: calc(50% + 20px);
`;


export const EFTInfoTextBoxLeft = styled.h3`
    float: left;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    padding: 0px; 
`;

export const EFTInfoTextBoxRight = styled.h3`
    float: right;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    padding: 0px; 
`;

export const ETFCardWrapper = styled.div`
    content: 'ETF Card';
    width: calc(100%);
    height: auto;
    cursor: pointer;
`;

export const MacroSummary = styled.div`
    position: absolute;
    overflow: hidden;
    width: 63%;
    padding: 20px;
    padding-left: 51px;
    padding-bottom: 20px;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    h2 {
        margin: 5px;
        margin-left: 0;
    }
`;