export const PercentileMoves = {
    label: null,
    values: [
        {
            label: 'Top 1% Moves',
            value: 1,
        },
        {
            label: 'Top 3% Moves',
            value: 3,
        },
        {
            label: 'Top 5% Moves',
            value: 5,
        },
        {
            label: 'Top 10% Moves',
            value: 10,
        },
        {
            label: 'Top 20% Moves',
            value: 20,
        },
    ],
};

export const ImpactScore = {
    label: null,
    values: [
        {
            label: 'Very High Impact Score',
            value: 0.4,
        },
        {
            label: 'High Impact Score',
            value: 0.3,
        },
        {
            label: 'Mild Impact Score',
            value: 0.2,
        },
        {
            label: 'Low Impact Score',
            value: 0.1,
        },
        {
            label: 'All',
            value: 0,
        },
    ],
};