import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import useOnScreen from '../../../../../Controllers/hooks/onScreenHook';
import Loading from '../../../LoadingSpinner';
import supabase from '../../../../../Controllers/utils/supabaseClient';
import { WarningText } from '../../../../StyledComponents/UserFormStyledComponents';
import { StateContext } from '../../../../../Controllers/context/state';
import StockCardInfo from './components/StockCardInfo';

const CardContainer = styled.div`
	width: 400px;
	margin: 0 4px;
	height: 200px;
	background: #0a1926;
	border: 1px solid ${({ isHovered }) => (isHovered ? '#5a9bce' : '#2c4961')};
	border-radius: 8px;
	text-align: left;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	filter: ${({ blurred }) => (blurred ? 'blur(4px)' : 'none')};

	&:hover {
		cursor: pointer;
	}
`;

const StockChartCard = ({
	blurred,
	cardData,
	isHovered,
	onMouseEnter,
	onMouseLeave,
}) => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const ref = useRef(null);
	const isVisible = useOnScreen(ref);
	const { sort } = useContext(StateContext);

	const fetchData = async ({ retry = true }) => {
		let data;

		const res = await supabase.rpc('get_expanded_summary_for_chart', {
			_symbol: cardData.symbol,
			_aggr: cardData.aggr,
			_epoch: cardData.ts / 1000,
			_corrasset: sort.correlationAsset,
		});
        
		if (res?.error) {
			const error = new Error(res?.error?.message);
			if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
				fetchData({ retry: false });
                
				return;
			}
		}

		if (res?.data?.length) {
			data = res?.data?.[0]?.data_row;
		}

		setData(data);
		setLoading(false);
	};

	useEffect(() => {
		if (isVisible) {
			fetchData({ retry: true });
		}
	}, [isVisible, cardData, sort.correlationAsset]);

	return (
		<CardContainer
			ref={ref}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			isHovered={isHovered}
			blurred={blurred}
		>
			{loading ? (
				<Loading />
			) : data ? (
				<StockCardInfo data={data} />
			) : (
				<WarningText>No Data Available</WarningText>
			)}
		</CardContainer>
	);
};

export default StockChartCard;
