import { useState, useRef, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { Input, WarningText } from '../StyledComponents/UserFormStyledComponents';
import AssetCheck from '../../Controllers/utils/assetCheck';
import { StateContext } from '../../Controllers/context/state';

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SearchBox = styled(Input)`
  width: 100%;
  border: 1px solid ${AppColors.MRNAVY60};
  border-radius: 2px;
  padding: 9px 4px 9px 40px;
  background-color: white !important;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  font-size: 14px;
  line-height: 24px;
  color: black !important;
`;

const DropdownMenu = styled.div`
  background: ${AppColors.MRNAVY20};
  color: ${AppColors.MRLITE90};
  border: 1px solid ${AppColors.MRNAVY60};
  box-sizing: border-box;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 999;
`;

const DropdownItem = styled.div`
  padding: 5px 10px;

  &:hover {
    background: ${AppColors.MRBLUE};
    color: ${AppColors.MRNAVY20};
  }
`;

const Tooltip = styled(WarningText)`
  position: absolute;
  width: fit-content;
  top: 50%;
  left: ${props => (props.position === 'right' ? 'calc(100% + 10px)' : '-80px')};
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: ${AppColors.MRRED};
  color: ${AppColors.MRNAVY20};
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 999;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    ${props =>
        props.position === 'right'
            ? 'left: -8px'
            : 'right: -8px'};
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    ${props =>
        props.position === 'right'
            ? `border-right: 8px solid ${AppColors.MRRED}`
            : `border-left: 8px solid ${AppColors.MRRED}`};
  }
`;

const SearchDropdown = ({ handleSelectTicker, handleSubmit, handleTickerChange, options, placeholder, position, searchRef }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [searchText, setSearchText] = useState('');
  const [validTicker, setValidTicker] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const wrapperRef = useRef(null);
  const {
    assetKeys,
    assetNames,
    assetTickers,
    setSearching,
    setShowMonthlySummary,
  } = useContext(StateContext);

  // Preprocess options into a map for faster lookups
  const optionsMap = useMemo(() => {
    const map = new Map();
    options.forEach(option => {
      map.set(option.label.toLowerCase(), option);
    });
    return map;
  }, [options]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setShowMonthlySummary(false);
      e.target.select();
      AssetCheck({
        assetKeys,
        assetNames,
        assetTickers,
        handleSubmit,
        inputVal,
        setDropdownOpen,
        setValidTicker,
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = event => {
    if (handleTickerChange) handleTickerChange(event.target.value);
    setValidTicker(true);
    setSearchText(event.target.value);
    setInputVal(event.target.value);
    setDropdownOpen(true);
  };

  const handleSelect = value => {
    setShowMonthlySummary(false);
    handleTickerChange ? handleTickerChange(value) :
      handleSelectTicker ? handleSelectTicker(value) :
      handleSubmit(value);
    setSearchText(value);
    setInputVal(value);
    if (searchRef) searchRef.current.select();
    setDropdownOpen(false);
    setSearching(false);
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredOptions(options);
      return;
    }
    const lowerSearchText = searchText.toLowerCase();
    const filtered = Array.from(optionsMap.entries()).reduce((acc, [key, value]) => {
      if (key.includes(lowerSearchText)) {
        acc.push(value);
      }
      return acc;
    }, []);
    setFilteredOptions(filtered);
  }, [searchText, options, optionsMap]);

  const prioritizeBestFit = (a, b) => {
    const aValue = a.label.toLowerCase();
    const bValue = b.label.toLowerCase();
    const searchTextLower = searchText.toLowerCase();

    if (aValue.startsWith(searchTextLower) && !bValue.startsWith(searchTextLower)) {
      return -1;
    }

    if (!aValue.startsWith(searchTextLower) && bValue.startsWith(searchTextLower)) {
      return 1;
    }

    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  };

  return (
    <SearchWrapper ref={wrapperRef}>
      <SearchBox
        onChange={handleChange}
        onFocus={() => setDropdownOpen(true)}
        onClick={() => !dropdownOpen ? setDropdownOpen(true) : null}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || 'Search Assets...'}
        ref={searchRef}
        type='search'
        value={searchText}
      />
      {!validTicker && (
        <Tooltip position={position}>
          Unknown
        </Tooltip>
      )}
      {dropdownOpen && (
        <DropdownMenu>
          {filteredOptions?.sort(prioritizeBestFit).map(option => (
            <DropdownItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </SearchWrapper>
  );
};

export default SearchDropdown;
