import { formatMarketCap, formatPrice, round } from './index';

const setSortDisplay = (data, key, state = 0, showCorrelatedAssetsPopup) => {

    const SortDisplay = {
        beta: {
            label: `Beta to ${data?.correlation_symbol}: `,
            value: `${round(data?.beta, 2)}`,
        },
        close: {
            label: 'Price ',
            value: `${formatPrice(data?.close)}`,
        },
        correlation: {
            label: `${data?.correlation_symbol} Correlation: `,
            value: `${round(data?.correlation, 2)}`,
        },
        date_return_oc_voladj:{
            label: state > -2 ? 'Vol Adj Return' : 'Return ',
            value: `${round(data?.date_return_oc_voladj)}σ`,
        },
        date_return_oc: {
            label: 'Daily Return ',
            value: `${round(data?.date_return_oc * 100)}%`,
        },
        'date_return_oc*mcap_mm': {
            label: state > -2 ? 'Market Cap ∆' : 'Mkt Cap ∆ ',
            value: `${formatMarketCap(data?.date_return_oc * data?.mcap_mm)}`,
        },
        "COALESCE((dtm_summary->'dtm_summary_meta'->'information_score'->'raw')::numeric,0)": {
            label: 'Information Score',
            value: `${data?.dtm_summary?.dtm_summary_meta?.information_score?.label || '--'}`
        }
    };

    if (showCorrelatedAssetsPopup) {
        return (
            <>
                {SortDisplay.correlation.label}
                {state > -2 ? <br/> : null}
                {SortDisplay.correlation.value}
            </>
        );
    }

    return (
        <>
            {SortDisplay[key].label}
            {state > -2 ? <br/> : null}
            {SortDisplay[key].value}
        </>
    );
};

export default setSortDisplay;
