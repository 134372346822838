import { formatPrice, round, formatDateYear, formatDateTime } from '../../Controllers/utils';
import {
	StockInfoContainer,
	StockInfoTextBoxLeft,
	StockInfoTextBoxCenter,
	StockInfoTextBoxRight,
} from '../StyledComponents/StockCardStyledComponents';
import {
	DirectionDownIcon,
	DirectionMoveIcon,
	DirectionUpIcon,
} from '../Icons/Icons';

const StockInfo = ({ cardDimensions, chartType, data, isCard }) => {
	const showWhite = !!data?.time_utc && isCard;
	let moveDirection;
	let moveColor;
	let plusSign;

	if (round(data?.date_return_oc * 100, 1) > 0) {
		plusSign = '+';
		moveDirection = <DirectionUpIcon />;
		moveColor = 'MRGREEN';
	} else if (round(data?.date_return_oc * 100, 1) < 0) {
		moveDirection = <DirectionDownIcon />;
		moveColor = 'MRRED';
	} else if (round(data?.date_return_oc * 100, 1) === 0) {
		moveDirection = <DirectionMoveIcon />;
		moveColor = 'MRLITE80';
	}

	return (
		<StockInfoContainer
			cardDimensions={cardDimensions?.data}
			moveColor={moveColor}
		>
			<StockInfoTextBoxLeft showWhite={showWhite}>
                {isCard ?
                    (chartType === "10m" ?
                        formatDateTime(data?.time_utc) : formatDateYear(data?.time_utc)) :
                    data?.symbol
                }
			</StockInfoTextBoxLeft>

			<StockInfoTextBoxCenter>
				{formatPrice(data?.close)}
			</StockInfoTextBoxCenter>

			<StockInfoTextBoxRight>
				{plusSign}
				{round(data?.date_return_oc * 100, 1)}%{moveDirection}
			</StockInfoTextBoxRight>
		</StockInfoContainer>
	);
};

export default StockInfo;
