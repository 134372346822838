import { useEffect, useState, useContext, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import Drawer from '../../Global/Components/Drawer';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import ExportToCsv from '../../Global/Components/ExportToCsv';
import FeedViewer from "../../Global/Views/FeedViewer/FeedViewer";
import { Header, StyledLabel } from '../../Global/StyledComponents/UserFormStyledComponents';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import SortBar from '../../Global/Components/SortBar';
import {
    ViewModule,
} from "../../Global/StyledComponents/AppStyledComponents";
import StockCardView from '../../Global/Views/StockCardView/StockCardView';
import styled from 'styled-components';
import { StateContext } from '../../Controllers/context/state';
import supabase from '../../Controllers/utils/supabaseClient';
import TableView from '../../Global/Views/TableView/TableView';
import StockCardGridComponent from '../../Global/Layouts/StockCardGrid';
import { formatDateYear } from '../../Controllers/utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
`;

const UnusualMovesMonitor = () => {
    const afterhoursFilter = {
        marketReaderHot: "(date_return_oc_percentile NOT BETWEEN 0.06 AND 0.94) OR (date_range_hl_percentile > 0.95)",
        marketCap: "mcap_mm>500",
        assetType: "type='Common Stock' OR type='ADR'",
        sameDay: "market_date > (SELECT CASE WHEN date_trunc('day', NOW()) = date_trunc('day',market_open) THEN date ELSE date_trunc('day',market_close_prev) END as ref_date FROM market_calendar_ref mcr where market = 'NYSE' and NOW() BETWEEN market_close_prev and market_close)",
        highImpact: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.135 ELSE false END", 
        summary: "dtm_summary NOTNULL"
    };
    const [afterhoursTickerList, setAfterhoursTickerList] = useState([]);
    const afterhoursRef = useRef();
    const [loading, setLoading] = useState(true);
    const [loadingAfterhours, setLoadingAfterhours] = useState(true);
    const sameDayFilter = {
        marketReaderHot: "(date_return_oc_percentile NOT BETWEEN 0.06 AND 0.94) OR (date_range_hl_percentile > 0.95)",
        marketCap: "mcap_mm>500",
        assetType: "type='Common Stock' OR type='ADR'",
        sameDay: "market_date = (SELECT CASE WHEN date_trunc('day', NOW()) = date_trunc('day',market_open) THEN date ELSE date_trunc('day',market_close_prev) END as ref_date FROM market_calendar_ref mcr where market = 'NYSE' and NOW() BETWEEN market_close_prev and market_close)",
        highImpact: "CASE WHEN dtm_summary->'dtm_summary_meta'->'impact_score' != 'null' THEN (dtm_summary->'dtm_summary_meta'->'impact_score')::numeric > 0.135 ELSE false END", 
        summary: "dtm_summary NOTNULL"
    };
    const [tickerList, setTickerList] = useState([]);

    const {
        development,
        expandedViewTicker,
        isLoading,
        logError,
        setShowErrorWindow,
        setSort,
        showExpandedView,
        sort,
        viewStyle,
    } = useContext(StateContext);

    const fetchTickerList = ({ retry = true }) => {
        supabase.rpc('getfilteredsortedlist', { request: {
            filter: sameDayFilter,
            sort,
        }}).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    fetchTickerList({ retry: false });

                    return;
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }
    
                return;
            }

            const { entities } = res?.data?.[0]?.symbol;
            setTickerList(entities);
            setLoading(false);
        });
    };

    const fetchAfterhoursTickerList = ({ retry = true }) => {
        supabase.rpc('getfilteredsortedlist', { request: {
            filter: afterhoursFilter,
            sort,
        }}).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);

                if (retry && res?.error?.message !== 'FetchError: Failed to fetch') {
                    fetchAfterhoursTickerList({ retry: false });
                }

                if (res?.error?.message !== 'FetchError: Failed to fetch') {
                    setShowErrorWindow(true);
                }
    
                return;
            }

            const { entities } = res?.data?.[0]?.symbol;
            setAfterhoursTickerList(entities);
            setLoadingAfterhours(false);
        });
    };

    useEffect(() => {
        if (!isLoading) {
            fetchTickerList({ retry: true });
            fetchAfterhoursTickerList({ retry: true });
        }
    }, [isLoading, sort]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchTickerList({ retry: true });
            fetchAfterhoursTickerList({ retry: true });
        }, 180000);
        setSort({
            asc: false,
            abs: true,
            key: 'date_return_oc_voladj',
        });
        
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (isLoading) {
        return (
            <ViewModule>
                <Loading />
            </ViewModule>
        );
    }

    return (
        <>
            <ViewModule>
                {(!isMobile || isTablet) &&
                    <div style={{ position: 'absolute', top: 20, right: 65 }}>
                        <ExportToCsv />
                    </div>
                }
                <Header style={{ margin: 5 }}>
                    <div>
                        Current Unusual Moves
                    </div>
                    <div>
                        US Stocks/ADRs
                    </div>
                </Header>
                <div style={{ display: 'flex', height: 'calc(100% - 40px)' }}>
                    <Wrapper>
                        <SortBar />
                        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 44px)' }}>
                        <div style={{ flex: '0 1 auto', maxHeight: '50%' }} ref={afterhoursRef}>
                            {afterhoursTickerList?.length ?
                                <>
                                    <StyledLabel style={{ fontWeight: 'bold',  margin: 5, marginLeft: 10 }}>
                                        Afterhours Movers
                                    </StyledLabel>
                                    <StockCardGridComponent height={`${afterhoursRef?.current?.clientHeight - 34}px`} >
                                        {loadingAfterhours ?
                                            <Loading /> :
                                            viewStyle?.data?.showCards ?
                                                <StockCardView tickerList={afterhoursTickerList} /> :
                                                <TableView tickerList={afterhoursTickerList} />
                                        }
                                    </StockCardGridComponent>
                                </> :
                                null
                            }
                        </div>
                            {loading ?
                                <Loading /> :
                                <div style={{ flex: '1 1 auto', height: `calc(100% - ${afterhoursRef?.current?.clientHeight}px)`}}>
                                    <StyledLabel style={{ fontWeight: 'bold', margin: 5, marginLeft: 10 }}>
                                        {formatDateYear(new Date().getTime())}
                                    </StyledLabel>
                                    <StockCardGridComponent height={`calc(100% - 34px)`} >
                                        {viewStyle?.data?.showCards ?
                                            <StockCardView tickerList={tickerList} /> :
                                            <TableView tickerList={tickerList} />
                                        }
                                    </StockCardGridComponent>
                                </div>
                            }
                        </div>
                    </Wrapper>
                </div>
            </ViewModule>
            <Drawer open={showExpandedView} rotate={'true'} >
                <FeedViewer tickerList={tickerList} title='News Feed'/>
                {development &&
                    <MacroMovers title='Macro Movers'/>
                }
                <ExpandedView title={`${expandedViewTicker} Summary`}/>
                <MacroSummariesFeed title="Macro Overview" />
            </Drawer>
        </>
    )
}

export default UnusualMovesMonitor;
