import { useContext, useRef, useState } from 'react';
import {
    CardContainer,
    DisplayInlineHalfLTop
} from '../StyledComponents/StockCardStyledComponents';
import {
    Input,
    WarningText,
    SuccessMessage,
} from '../StyledComponents/UserFormStyledComponents';
import supabase from '../../Controllers/utils/supabaseClient';
import { StateContext } from '../../Controllers/context/state';

import SearchDropdown from './SearchDropdown';
import AssetCheck from '../../Controllers/utils/assetCheck';
import { AddToListIcon, CloseIcon } from '../Icons/Icons';
import TickerInputArea from './TickerInputArea';
import { StyledButton } from '../StyledComponents/UserFormStyledComponents';
import { AuthContext } from '../../Controllers/context/auth';

const AddToWatchlistForm = ({ createNew, handleShowForm, values = [] }) => {
    const [addSuccess, setAddSuccess] = useState(false);
    const [checkTickers, setCheckTickers] = useState(false);
    const [emptyName, setEmptyName] = useState(false);
    const [error, setError] = useState(false);
    const searchRef = useRef(null);
    const [tickerArray, setTickerArray] = useState(values);
    const [watchlistName, setWatchlistName] = useState('');
    const [watchlistNameExists, setWatchlistNameExists] = useState(false);

    const { user } = useContext(AuthContext);

    const {
        assets,
        assetKeys,
        assetNames,
        assetTickers,
        fetchAllWatchlists,
        fetchAllWatchlistTickers,
        logError,
        selectedWatchlist,
        setSelectedWatchlist,
        setShowAddToWatchlistPopup,
        setShowErrorWindow,
        watchlists,
    } = useContext(StateContext);
    const watchlistNames = watchlists?.map((watchlist) => watchlist.label);

    const fetchWatchlist = ({ retry = true }) => {
        supabase.from('user_preferences').select('*').eq('id', selectedWatchlist?.id).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    fetchWatchlist({ retry: false });
    
                    return;
                }
        
                setShowErrorWindow(true);
    
                return;
            }

            setSelectedWatchlist(res?.data[0]);
        });
    };

    const filterTickerArray = (array) => {
        const filteredArray = array?.filter(ticker =>
            AssetCheck({
                assetKeys,
                assetNames,
                assetTickers,
                handleSubmit: () => {},
                inputVal: ticker,
            }) &&
            ticker !== ""
        ).map(ticker => ticker.toUpperCase());

        return filteredArray;
    };

    const handleAddToWatchList = (tickers) => {
        const updatedWatchlist = {
            data: [
                ...tickers.filter(ticker => !selectedWatchlist?.data?.includes(ticker)),
                ...selectedWatchlist?.data
            ],
            id: selectedWatchlist?.id,
            meta: selectedWatchlist?.meta,
            user_id: selectedWatchlist?.user_id,
        };

        supabase.from('user_preferences').update(updatedWatchlist)
            .eq('id', selectedWatchlist?.id).then((res) => {
                if (res?.error) {
                    setError(true);
                } else {
                    fetchWatchlist({ retry: true });
                    fetchAllWatchlists({ retry: true });
                    fetchAllWatchlistTickers({ retry: true });
                    setError(false);
                    setAddSuccess(true);
                    setTimeout(() => {
                        setAddSuccess(false);
                        setTickerArray([]);
                        setShowAddToWatchlistPopup(false);
                    }, 2000);
                }
            });
    };

    const handleChange = (e) => {
        const { id, value } = e?.target;

        if (id === 'watchlistName') {
            setWatchlistNameExists(watchlistNames?.includes(value));
            setWatchlistName(value);
        }
        setEmptyName(false);
    };

    const handleCreateNewWatchlist = (tickers) => {
        supabase.from('user_preferences').insert([{
            data: tickers,
            meta: {
                name: watchlistName,
                created_at: new Date(),
            },
            type: 'watchlist',
            user_id: user?.id,
        }]).select().then((res) => {
            if (res?.error) {
                setError(true);
            } else {
                fetchAllWatchlists({ retry: true });
                fetchAllWatchlistTickers({ retry: true });
                setSelectedWatchlist(res?.data[0]);
                setError(false);
                setAddSuccess(true);
                setWatchlistName('');
                setTimeout(() => {
                    setAddSuccess(false);
                    setTickerArray([]);
                }, 2000);
                handleShowForm && handleShowForm();
            }
        });
    };

    const handleSubmit = () => {
        const filteredArray = filterTickerArray(tickerArray);

        if (filteredArray?.length) {
            if (createNew) {
                if (!watchlistName?.length) {
                    setEmptyName(true);
                    return;
                }
                
                handleCreateNewWatchlist(filteredArray);
                return;
            }

            handleAddToWatchList(filteredArray);
            return;

        }

        setCheckTickers(true);
        setTimeout(() => setCheckTickers(false), 2000)
        return;
    };

    const handleSelectTicker = (ticker) => {
        setTickerArray(prevState => ([
            ...prevState,
            ticker,
        ]));
        setCheckTickers(false);
    };

    return (
        <CardContainer percentileMove='EPIS' style={{ position: 'relative', margin: '15px auto', width: '100%', flexDirection: 'column', height: '450px' }}>
            {handleShowForm &&
                <CloseIcon onClick={handleShowForm} style={{ position: 'absolute', right: 10, top: 10 }} />
            }
            {addSuccess ? 
                <SuccessMessage>
                    {createNew ?
                        'Your watchlist has been created.' :
                        'Your assets have been added to your watchlist.'

                    }
                </SuccessMessage> :
            <>
                <DisplayInlineHalfLTop>
                    {createNew ?
                        'Create New Watchlist' :
                        'Add to Watchlist'
                    }
                    &nbsp; <AddToListIcon />
                </DisplayInlineHalfLTop>
                <div
                    style={{
                        height: '100%',
                        margin: '5px',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <div style={{ marginBottom: '10px' }}>
                            {createNew ?
                                 <Input
                                    style={{ width: '100%'}}
                                    id='watchlistName'
                                    onChange={handleChange}
                                    placeholder='Enter watchlist name (required)'
                                    type='text'
                                    value={watchlistName}
                                /> :
                                null
                            }
                        </div>
                        {watchlistNameExists ?
                            <WarningText style={{ textAlign: 'center' }}>Watchlist name already exists.</WarningText> :
                            null
                        }
                        {emptyName ?
                            <WarningText style={{ textAlign: 'center' }}>Add watchlist name.</WarningText> :
                            null
                        }
                        {!createNew &&
                            <SearchDropdown
                                handleSubmit={handleSubmit}
                                handleSelectTicker={handleSelectTicker}
                                options={assets}
                                position='left'
                                searchRef={searchRef}
                            />
                        }
                        <TickerInputArea setTickerArray={setTickerArray} tickerArray={tickerArray} />
                        {checkTickers ?
                            <WarningText style={{ textAlign: 'center' }}>No valid tickers. Please check and try again.</WarningText> :
                            null
                        }
                        {error ?
                            <WarningText style={{ textAlign: 'center' }}>
                                {createNew ?
                                    'Create watchlist failed. Please try again.' :
                                    'Failed to add ticker to watchlist. Please try again.'
                                }
                            </WarningText> :
                            null
                        }
                    </div>
                    <StyledButton disabled={watchlistNameExists} id='submit' onClick={handleSubmit} type='button' >
                        {createNew ? 'Create New Watchlist' : 'Add To Selected Watchlist'}
                        &nbsp; <AddToListIcon />
                    </StyledButton>
                </div>
            </>
            }
        </CardContainer>
    );
};

export default AddToWatchlistForm;
