import { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    Form,
    FormWrapper,
    Header,
    Input,
    SubmitButton,
    SuccessMessage,
    WarningText,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import supabase from '../../Controllers/utils/supabaseClient';
import { WhiteTextGlassLogo } from "../../Global/Logos/Logos";

const SetPasswordMonitor = ({
    session,
    setCustomerProfile,
    setIsAuthenticated,
    setSession,
    setSubscription,
    setUser
}) => {
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [disabled, setDisabled] = useState(!passwordsMatch || password.length < 6 || !confirmPassword);

    useEffect(() => {
        supabase.auth.getSession().then((res) => {
            if (!res.data.session) {
                setSession(false);
                setTimeout(() => {
                    navigate('/forgotPassword');
                }, 3000)
            } else {
                setSession(res.data.session);
            }
        });
    }, []);

    useEffect(() => {
        setDisabled(!passwordsMatch || password.length < 6 || !confirmPassword);
    }, [confirmPassword, password, passwordsMatch]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordsMatch(e.target.value === confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordsMatch(e.target.value === password);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);

        supabase.auth.updateUser({password}).then(async (res) => {
            const { data, error } = res;
            const profileRes = await supabase.from('profiles').select('*');
            const { billing_email, regulatoryReport, trialStatus } = profileRes.data[0];
            const { user } = data;

            setUser(user);
            setSession(session);
            if (user?.role === 'authenticated') {
                const profile = await supabase.functions.invoke('customerProfile', {
                    body: {
                        billingEmail: billing_email, 
                        userEmail: user.email,
                    },
                });

                if (!profile?.data) {
                    handleSubmit(e);

                    return;
                }

                setCustomerProfile(JSON.parse(profile?.data));
    
                const { accessLevels, subscriptions } = JSON.parse(profile?.data);
                setSubscription(subscriptions[0]);
                
                if (!subscriptions.length && !trialStatus) {
                    const { data, error } = await supabase.functions.invoke('createTrial', {
                        body: {
                            userEmail: user.email,
                        }
                    });

                    if (error) {
                        alert(error.message);
        
                        return;
                    }

                    const { ok } = JSON.parse(data);

                    if (ok) {
                        supabase.from('profiles').update({
                            trial_status: true
                        }).eq('id', user?.id).then((res) => {
                            handleSubmit(e);
                        });
                        setDisabled(false);
                        return;
                    } else {
                        setError(true);
                        setDisabled(false);
                    }
                    return;
                };


                // if (
                //     !regulatoryReport &&
                //     (billing_email === user.email || !billing_email) &&
                //     !subscriptions?.length
                // ) {
                //     navigate('/regulatory');
    
                //     return;
                // }

                if (!subscriptions.length) {
                    navigate('/selectPlan')
    
                    return;
                }
    
                if (accessLevels.includes('pro')) {
                    const ssoToken = await supabase.functions.invoke('hellonext', {
                        body: {
                            email: user.email,
                            // name: 'placeholder',
                            add_as_customer: true
                        }
                    });
    
                    user.hnssotoken = ssoToken.data;
    
                    const widget = window.HNWidget.init({
                        title: "Post Widget",
                        token: process.env.REACT_APP_HNWIDGET_TOKEN,
                        jwtToken: ssoToken,
                        // selector: "#widgetID", // Optional: Widget will open up when this element is clicked
                        placement: "right",
                        showOnlySubmission: true, 
                        bucketId: "13762",
                        launcherIconURL: '/img/bugicon.png'
                    });
    
                    setIsAuthenticated(true);
                    navigate('/');

                    if (!isMobile || isTablet) {
                        return;
                    } else {
                        window.location.reload(false);
                        return;
                    }
    
                } else {
                    navigate('/accessDenied', { state: { user: { email: user.email, billingEmail: billing_email } } });
    
                    return;
                }
            }
        });

    };

    if (!session) {
        return (
            <Container>
                <FormWrapper>
                    <Header>Your access key has expired!</Header>
                </FormWrapper>
            </Container>
        );
    }

    return (
        <Container>
            <FormWrapper>
                <WhiteTextGlassLogo title="Market Reader logo" />
                <Header><br />Choose Your New Password</Header>
                <Form onSubmit={handleSubmit}>
                    <Input
                        className='login'
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder='Password'
                    />
                    <Input
                        className='login'
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder='Confirm Password'
                    />
                    <SuccessMessage>
                        Password must be at least 6 characters.
                    </SuccessMessage>
                    {error && <WarningText>There was an error in setting up your password. Please try again.</WarningText>}
                    {!passwordsMatch && <WarningText>Passwords do not match!</WarningText>}
                    <SubmitButton disabled={disabled} type="submit">Set Password</SubmitButton>
                </Form>
            </FormWrapper>
        </Container>
    );
};

export default SetPasswordMonitor;
