import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AlertFeed from '../../Global/Views/FeedViewer/AlertFeed';
import { AuthContext } from '../../Controllers/context/auth';
import {
	BlankSpacer,
	ViewModule,
} from '../../Global/StyledComponents/AppStyledComponents';
import Chart from './components/Chart';
import Drawer from '../../Global/Components/Drawer';
import ExpandedView from '../../Global/Components/ExpandedView/ExpandedView';
import FeedViewer from '../../Global/Views/FeedViewer/FeedViewer';
import {
	Input,
	SearchArea,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import Loading from '../../Global/Components/LoadingSpinner';
import MacroSummariesFeed from '../../Global/Views/FeedViewer/MacroSummariesFeed';
import MacroMovers from '../../Global/Views/FeedViewer/MacroMovers';
import SearchDropdown from '../../Global/Components/SearchDropdown';
import { StateContext } from '../../Controllers/context/state';
import { useParams } from 'react-router-dom';
import StockChartWrapper from '../../Global/Components/StockChartWrapper/StockChartWrapper';

const AssetMonitor = () => {
	const [dateDisplay, setDateDisplay] = useState(new Date().toISOString().split('T')[0]);
	const [selectedDate, setSelectedDate] = useState(new Date().getTime() / 1000);
	const params = useParams();
	const symbol = params.symbol + (params['*'] ? `/${params['*']}` : '');
	const [startingWidth, setStartingWidth] = useState();
	const {
		assets,
		development,
		expandedViewTicker,
		fullNameKey,
		isLoading,
		logError,
		newAlerts,
		setExpandedViewTicker,
	} = useContext(StateContext);
	const navigate = useNavigate();
	const { session } = useContext(AuthContext);
	const [width, setWidth] = useState();

	const handleDateChange = (e) => {
		setSelectedDate(new Date(e.target.value).getTime() / 1000);
		setDateDisplay(e.target.value);
	};

	const handleSearchSubmit = (value) => {
		navigate(`/ticker/${value}`);
	};

	useEffect(() => {
		setWidth(window.innerWidth - 554);
		setStartingWidth(window.innerWidth - 554);
	}, [symbol]);

	useEffect(() => {
		setExpandedViewTicker(symbol);
	}, [symbol]);

	if (isLoading) {
		return (
			<ViewModule>
				<Loading />
			</ViewModule>
		);
	}

	return (
		<>
			<ViewModule>
				<div style={{ display: 'flex', height: '100%' }}>
					<div style={{ marginRight: '10px' }}>
						<StockChartWrapper
                            assetPage
							assets={assets}
                            handleNavigation={handleSearchSubmit}
							symbol={symbol}
							width={width}
						/>
					</div>
				    <div style={{ height: '100%', width: '400px' }}>
						<ExpandedView assetPage />
				    </div>
				</div>
			</ViewModule>
			
			<Drawer rotate={'true'} setWidth={setWidth} startingWidth={startingWidth}>
				{/* <ExpandedView title={`${expandedViewTicker} Summary`} /> */}
				<FeedViewer title='News Feeds' />
				{development && <MacroMovers title='Macro Movers' />}
				{/* <AlertFeed id='alerts' number={newAlerts} title='Watchlist Alerts' /> */}
				<MacroSummariesFeed title='Macro Overview' />
			</Drawer>
		</>
	);
};

export default AssetMonitor;
