import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AppColors } from './Graphics';

export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => (props.isMobile ? null : '100vh')};
    width: 100vw;
    background-color: ${props => props.isMobile ? AppColors.MRNAVY15 : AppColors.MRNAVY30};
`;

export const SignUpContainer = styled.div`
     position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    background-color: ${props => props.code || props.isMobile ? AppColors.MRNAVY15 : AppColors.MRNAVY30};
    padding: ${props => props.isMobile ? '90px 0' : null};
`;

export const Form = styled.form`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
`;

export const FormWrapper = styled.div`
    color: ${AppColors.MRWHITE};
    width: ${props => (props.isMobile ? 'calc(100% - 2rem)' : '400px')};
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2d3748;
    padding: 2rem;
    border-radius: 7px;
`;

export const Header = styled.h2`
    color: white;
    text-align: center;
    font-family: Mulish, Arial, Helvetica, sans-serif;
    font-weight: 800;
`;

export const Input = styled.input`
    color: ${AppColors.MRLITE80};
    background-color: ${AppColors.MRNAVY15};
    border: 1px solid ${AppColors.MRNAVY60};
    border-radius: 2px;
    font-size: 14px;
    line-height: 24px;
    color-scheme: dark;
    margin-right: 10px;
    font-family: inherit;

    &.login {
        margin: 10px;
        width: 90%;
        height: 40px;
        padding: 0 10px;
        background: ${AppColors.MRNAVY20} !important;
        border: 1px solid ${AppColors.MRNAVY60};
    }

    &:focus {
        border: none;
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
    &:focus,
    &:hover {
        background-color: ${AppColors.MRNAVY40};
    }
    &::placeholder {
        color: ${AppColors.MRLITE80};
    }
`;


export const InputWrapper = styled(FormWrapper)`
    padding: 2px;
    width: 200px;
`;

export const MobileFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.isMobile ? '90%' : '40%'};
    height: ${props => props.isMobile || props.code ? 'auto' : '100vh'};
    background-color: ${props => props.isMobile ? null : AppColors.MRNAVY15};
`;

export const SearchArea = styled.div`
    margin-right: 20px;
    margin-top: 2px;
    width: 350px;
`;

export const StyledButton = styled.button`
    background: none;
    border: 1px solid ${AppColors.MRNAVY40};
    border-radius: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${props => props.disabled ? 'grey' : AppColors.MRNAVY40};
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 15px auto;
    color: ${AppColors.MRWHITE};

    &:focus, &:hover {
        background-color: ${props => props.disabled ? 'grey' : AppColors.MRNAVY20};
    }

    svg {
        margin-left: 10px;
        font-size: 14px;
    }
`;

export const CancelButton = styled(StyledButton)`
    background: ${AppColors.MRRED};
    color: ${AppColors.MRNAVY15};
    margin: 5px;
`;

export const CheckboxItem = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: ${props => props.checked ? AppColors.MRBLUE : AppColors.MRNAVY20};
    color: ${(props) => props.checked ? AppColors.MRNAVY20 : AppColors.MRWHITE};
    border: 1px solid ${AppColors.MRNAVY60};
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background: ${AppColors.MRNAVY60};
        color: ${AppColors.MRNAVY20};
    }
`;

export const CheckboxLabel = styled.label`
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
`;

export const ConfirmationButton = styled(StyledButton)`
    background: ${props => props.disabled ? 'grey' : AppColors.MRBLUE};
    color: ${AppColors.MRNAVY15};
    margin: 5px;
`;

export const StyledHyperLink = styled.a`
    color: ${AppColors.MRBLUE};
`;

export const StyledLabelInline = styled.p`
    margin: 0 5px;
    white-space: nowrap;
`;

export const StyledLabel = styled.p`
    margin: 25px 0px 2px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

export const StyledLink = styled(Link)`
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    color: ${AppColors.MRLITE80};
    font-family: inherit;
`;

export const StyledSelect = styled.select`
    width: 100%;
    cursor: pointer;
    border: 1px solid;
    border-color: ${AppColors.MRNAVY40};
    border-radius: 2px;
    padding: 10px;
    background: ${AppColors.MRNAVY15};
    font-size: 14px;
    line-height: 24px;

    option {
        background-color: ${AppColors.MRLITE80};
        color: ${AppColors.MRNAVY40};
    }
    &:focus, &:hover {
        background-color: ${AppColors.MRNAVY40};
    }
`;

export const SubmitButton = styled.button`
    width: 95%;
    background-color: ${props => (props.disabled ? 'grey' : '#38CC8C')};
    color: ${AppColors.MRNAVY20};
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
    text-transform: uppercase;

    &:hover {
        background-color: ${props => (!props.disabled ? '#67A88A' : 'grey')};
    }
`;

export const SuccessMessage = styled.div`
    font-size: 16px;
    color: darkgrey;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
`;

export const TextArea = styled.textarea`
    border: 1px solid ${AppColors.MRNAVY60};
    border-radius: 2px;
    color: ${AppColors.MRLITE80};
    font-size: 14px;
    line-height: 24px;
    color-scheme: dark;
    background-color: ${AppColors.MRNAVY15};
    font-family: inherit;
    width: 100%;
    height: 145px;
    padding: 10px;
`;

export const WarningText = styled.div`
    display: flex;
    color: ${AppColors.MRRED};
    margin: 0 auto;
    width: 90%;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const ToggleButton = styled(StyledButton)`
    color:${({ isActive }) => (isActive ? 'white' : 'grey')};
    height:100%;
    margin:0 5px;
    background:transparent;
    border: 1px solid ${({ isActive })=>(isActive ? 'white':AppColors.MRNAVY40)};

    &:hover{
        background:transparent;
        border: 1px solid white;

    }
`;