import React, { useContext } from 'react';
import styled from 'styled-components';
import { getPercentileColor, ordinalSuffixOf, round } from '../../../../../../Controllers/utils';
import { Headline } from '../../../../../StyledComponents/StockCardStyledComponents';
import { AppColors, CardColors } from '../../../../../StyledComponents/Graphics';
import { StateContext } from '../../../../../../Controllers/context/state';
import StockInfo from '../../../../StockInfo';

const BottomRow = styled.div`
	margin-top: auto;
	text-align: center;
	display: flex;
`;

const BottomRowItem = styled.div`
	flex: 1;
	text-align: center;
	font-size: 14px;
	display: ${({ isTime }) => (isTime ? 'flex' : 'block')};
	justify-content: ${({ isTime }) => (isTime ? 'center' : 'unset')};
	align-items: ${({ isTime }) => (isTime ? 'center' : 'unset')};
	color: ${({ moveColor }) => (moveColor ? AppColors[moveColor] : 'white')};
	background: ${({ percentileMove, noBackground }) => !noBackground && percentileMove ?
            CardColors[percentileMove]:
            'transparent'
            };
	border-radius: ${({ borderTopLeftRadius, borderTopRightRadius }) =>
                    `${borderTopLeftRadius ? '10px' : '0'} 
                    ${borderTopRightRadius ? '10px' : '0'} 0 0`
                    };
`;

const StockCardInfo = ({ data }) => {
	const { chartType, setChartInfo, setShowChartInfoWindow } = useContext(StateContext);
	let moveColor;
	const percentileMove = round(data?.date_return_oc * 100, 1);
	const color = getPercentileColor(data?.date_return_oc_percentile);

	if (percentileMove > 0) {
		moveColor = 'MRGREEN';
	} else if (percentileMove < 0) {
		moveColor = 'MRRED';
	} else if (percentileMove === 0) {
		moveColor = 'MRLITE80';
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100%',
			}}
			onClick={() => {
				setChartInfo(data);
				setShowChartInfoWindow(true);
			}}
		>
			<div style={{ padding: '15px 5px 0 5px' }}>
				<StockInfo data={data} isCard chartType={chartType} />
			</div>
			<div style={{ margin: '5px' }}>
                <Headline>
                    {data.summary?.[0]?.aggr === '10min' ? 
                        data.summary?.[0]?.headline :
                        (data.summary?.[0]?.dtm_headline || 'No headline available')
                    }
                </Headline>

			</div>
			<BottomRow>
				<BottomRowItem percentileMove={color} borderTopRightRadius>
					{ordinalSuffixOf(round(data?.date_return_oc_percentile * 100, 0))}{' '}
					<br />
					Percentile
				</BottomRowItem>
				<BottomRowItem noBackground moveColor={moveColor}>
					Vol Adj <br /> {round(data?.date_return_oc_voladj)}σ &nbsp;
				</BottomRowItem>
				<BottomRowItem percentileMove='EPIS' isTime borderTopLeftRadius>
					More Info
				</BottomRowItem>
			</BottomRow>
		</div>
	);
};

export default StockCardInfo;
